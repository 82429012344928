<template>
  <div>
    <div class="header">
      <ul class="search">
        <li class="search-item">
          <div class="names">车辆名称</div>
          <el-input v-model="name" placeholder="请输入车辆名称" clearable></el-input>
        </li>
        <li class="search-item">
          <div class="names">用户账号</div>
          <el-input v-model="id" placeholder="请输入用户账号" clearable></el-input>
        </li>
        <li class="search-item">
          <div class="names">参数项</div>
          <!-- <el-input v-model="params" placeholder="请输入参数项" clearable></el-input> -->
          <el-select v-model="params" placeholder="请输入参数项" clearable>
            <el-option
              v-for="item in paramsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li class="search-item">
          <!-- type="datetimerange" -->
          <div class="names timer">日期</div>
          <el-date-picker
            v-model="timer"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </li>
        <li class="search-item">
          <div class="names">反馈状态</div>
          <el-select v-model="status" placeholder="请选择反馈状态" clearable>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li class="search-item">
          <div class="searchMsg" @click="search">筛选</div>
          <div class="reset" @click="reset">重置</div>
        </li>
      </ul>
    </div>
    <div class="center">
      <div class="btn" @click="batchExamine">批量审核</div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{'text-align': 'center',background:'#BFDFF7',color:'#606266'}"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed="left"></el-table-column>
        <el-table-column
          type="index"
          :index="table_index"
          label="序号"
          width="120"
          fixed="left"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="vehicleName" label="车辆名称" width="180" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="vehicleModel" label="车辆型号" width="200" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="status" label="反馈状态" width="100" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 1">待审核</div>
            <div v-if="scope.row.status == 2">已录用</div>
            <div v-if="scope.row.status == 3">未录用</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="parameterName"
          label="修改参数项"
          :show-overflow-tooltip="true"
          width="100"
        ></el-table-column>
        <el-table-column prop="oldValue" label="原值" width="100" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div v-if="scope.row.oldValue">{{scope.row.oldValue}}</div>
            <div v-if="!scope.row.oldValue">-</div>
          </template>
        </el-table-column>
        <el-table-column prop="newValue" label="新值" width="100" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="mobilePhone" label="用户账号" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="createdTime" label="反馈时间" width="140" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="remark" label="备注" width="100" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div v-if="scope.row.remark">{{scope.row.remark}}</div>
            <div v-if="!scope.row.remark">-</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="160" fixed="right">
          <template slot-scope="scope">
            <span class="look" v-if="scope.row.status == 1" @click="radioMsg(scope.row.id)">审核</span>
            <span class="look" v-if="scope.row.status == 1" style="margin:10px">|</span>
            <span class="look" @click="lookCar(scope.row)">查看车型</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="block_pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="pageNum"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog width="400px" title="参数纠正审核" :visible.sync="dialogVisible" :show-close="false">
      <div class="radios" style="margin-bottom:10px">
        <span>反馈状态：</span>
        <el-radio v-model="radio" label="2">录用</el-radio>
      </div>
      <div class="radios">
        <span style="opacity: 0;margin-right:14px;">反馈状态：</span>
        <el-radio v-model="radio" label="3">不录用</el-radio>
      </div>
      <div class="but">
        <div class="sure" @click="sureSuccess">确认</div>
        <div class="cancel" @click="cancel">取消</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      radio: "",
      radioId: [],
      status: "",
      dialogVisible: false,
      name: "",
      id: "",
      params: "",
      timer: "",
      options: [
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "已录用",
        },
        {
          value: "3",
          label: "未录用",
        },
      ],
      paramsOptions: [
        {
          value: "车辆名称",
          label: "车辆名称",
        },
        {
          value: "厂商指导价(万元)",
          label: "厂商指导价(万元)",
        },
        {
          value: "近期成交价(万元)",
          label: "近期成交价(万元)",
        },
        {
          value: "车辆功能",
          label: "车辆功能",
        },
        {
          value: "变速器",
          label: "变速器",
        },
        {
          value: "发动机型号",
          label: "发动机型号",
        },
        {
          value: "排放标准",
          label: "排放标准",
        },
        {
          value: "车辆型号",
          label: "车辆型号",
        },
        {
          value: "生产厂家",
          label: "生产厂家",
        },
        {
          value: "总质量(kg)",
          label: "总质量(kg)",
        },
        {
          value: "品牌",
          label: "品牌",
        },
        {
          value: "车长(mm)",
          label: "车长(mm)",
        },
        {
          value: "车宽(mm)",
          label: "车宽(mm)",
        },
        {
          value: "车高(mm)",
          label: "车高(mm)",
        },
        {
          value: "货箱长(mm)",
          label: "货箱长(mm)",
        },
        {
          value: "货箱宽(mm)",
          label: "货箱宽(mm)",
        },
        {
          value: "货箱高(mm)",
          label: "货箱高(mm)",
        },
        {
          value: "底盘厂商",
          label: "底盘厂商",
        },
        {
          value: "底盘型号",
          label: "底盘型号",
        },
        {
          value: "整备质量(kg)",
          label: "整备质量(kg)",
        },
        {
          value: "轴距(mm)",
          label: "轴距(mm)",
        },
        {
          value: "轴数(个)",
          label: "轴数(个)",
        },
        {
          value: "发动机厂",
          label: "发动机厂",
        },
        {
          value: "排量(ml)",
          label: "排量(ml)",
        },
        {
          value: "功率(kW)",
          label: "功率(kW)",
        },
        {
          value: "燃料类别",
          label: "燃料类别",
        },
        {
          value: "轮胎规格",
          label: "轮胎规格",
        },
        {
          value: "轮胎数量(个)",
          label: "轮胎数量(个)",
        },
        {
          value: "弹簧片数(个)",
          label: "弹簧片数(个)",
        },
        {
          value: "最高车速(km/h)",
          label: "最高车速(km/h)",
        },
        {
          value: "综合燃料消耗(L/100km)",
          label: "综合燃料消耗(L/100km)",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
      pageSize: 10,
      pageNum: 1,
      total: 100,
    };
  },
  computed: {},
  watch: {
    dialogVisible(newVal, oldVal) {
      if (!newVal) {
        this.radioId = [];
      }
    },
  },
  created() {},
  mounted() {
    if (sessionStorage.getItem("pageKey") == 1) {
      let data = JSON.parse(sessionStorage.getItem("lookCar"));
      // console.log(data)
      this.pageNum = data.pageNum;
      this.pageSize = data.pageSize;
      this.name = data.name;
      this.id = data.id;
      this.params = data.params;
      this.timer = data.timer;
      this.status = data.status;
    } else {
      this.pageNum = 1;
      this.pageSize = 10;
    }
    this.getList();
  },
  methods: {
    // 序号自增
    table_index(index) {
      return index + this.pageSize * (this.pageNum - 1) + 1;
    },
    //获取数据
    async getList() {
      let list = {
        name: this.name,
        mobilePhone: this.id,
        parameterName: this.params,
        status: this.status,
        startTime: "",
        endTime: "",
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      if (this.timer) {
        // list.startTime = this.$moment(this.timer[0]).valueOf();
        list.startTime = this.timer[0].split(" ")[0];
        list.endTime = this.timer[1].split(" ")[0];
        // list.endTime = this.$moment(this.timer[1]).valueOf();
      }
      const { data: res } = await this.$http({
        url: "/vehicle/correction/getModifyRecords",
        method: "POST",
        data: list,
      });
      if (res.resultStates !== 0) return this.$message.error("数据获取失败");
      // if (!res.content) return this.$message.info("暂无数据");
      this.tableData = res.content.records;
      this.total = res.content.total;
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getList();
    },
    // 重置
    reset() {
      this.name = "";
      this.id = "";
      this.params = "";
      this.status = "";
      this.pageSize = 10;
      this.pageNum = 1;
      this.timer = "";
      this.getList();
    },
    // search
    search() {
      this.pageNum = 1;
      this.getList();
    },
    // 清除
    clear() {
      this.getList();
    },
    // 详情
    lookCar(data) {
      let obj = {
        vehicleId: data.vehicleId,
        vehicleModel: data.vehicleModel,
      };
      this.$store.commit("set_carMsg", obj);
      this.$router.push("/lookCar");
      let list = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: this.name,
        id: this.id,
        params: this.params,
        timer: this.timer,
        status: this.status,
      };
      sessionStorage.setItem("lookCar", JSON.stringify(list));
    },
    // 审核弹窗
    radioMsg(id) {
      this.dialogVisible = true;
      this.radio = "";
      this.radioId.push(id);
    },
    // 审核接口
    async sureSuccess() {
      let list = {
        status: this.radio,
        ids: this.radioId,
      };
      const { data: res } = await this.$http({
        url: "/vehicle/correction/updateReviewStatus",
        method: "POST",
        data: list,
      });
      console.log(res);
      this.dialogVisible = false;
      this.getList();
    },
    // 批量审核
    batchExamine() {
      console.log(this.radioId);
      if (!this.radioId.length) return this.$message.info("请选择审核数据");
      this.dialogVisible = true;
    },
    // 多选
    handleSelectionChange(data) {
      let list = [];
      data.forEach((item) => {
        console.log(item);
        list.push(item.id);
      });
      console.log(data);
      this.radioId = list;
    },
    cancel() {
      this.dialogVisible = false;
    },
  },
  destroyed() {
    sessionStorage.removeItem("pageKey");
  },
};
</script>
<style  lang="less" scoped>
.header {
  min-width: 1000px;
  // height: 176px;
  background: rgba(255, 255, 255);
  border-radius: 10px;
}
.search {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 20px;
}
.search-item {
  width: 400px;
  height: 50px;
  display: flex;
  align-items: center;
}
.search-item {
  margin-left: 10px;
}
.names {
  width: 100px;
  text-align: right;
  margin-right: 30px;
}
.timer {
  margin-right: 0px;
  text-align: center;
}
/deep/ .search-item .el-input__inner {
  width: 300px;
}
/deep/ .el-dialog__header {
  background: #1883e3;
  text-align: center;
}
/deep/ .el-dialog__header .el-dialog__title {
  color: #ffffff;
}
.search-item .searchMsg {
  width: 115px;
  height: 34px;
  background: rgba(0, 131, 227);
  border: 1px solid #1883e3;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  line-height: 34px;
  color: #ffffff;
  text-align: center;
  margin-left: 20px;
}
.search-item .reset {
  width: 115px;
  height: 34px;
  border: 1px solid #1883e3;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  line-height: 34px;
  color: #1883e3;
  text-align: center;
  margin-left: 20px;
}
.center {
  // width: 1650px;
  min-height: 109px;
  background: rgba(255, 255, 255);
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px 0 60px 0;
}
.btn {
  width: 100px;
  height: 34px;
  border: 1px solid #1883e3;
  border-radius: 6px;
  line-height: 34px;
  text-align: center;
  font-weight: 500;
  color: #1883e3;
  margin-left: 20px;
  margin-bottom: 20px;
}
.block_pagination {
  width: 100%;
  display: flex;
  justify-items: center;
}
.el-pagination {
  padding: 2px 5px;
  color: #303133;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 10px;
}
.look {
  font-size: 16px;
  // font-weight: bold;
  color: #333333;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(244, 250, 255);
  cursor: pointer;
  .look {
    color: #1883e3;
    font-weight: 500;
  }
}
/deep/ .el-table__body .el-table__row.hover-row td {
  background-color: rgba(244, 250, 255);
  cursor: pointer;
  .look {
    color: #1883e3;
    font-weight: 500;
  }
}
.radios {
  text-align: center;
  // font-size: 18px;
  color: #333333;
}
.but {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.sure {
  width: 115px;
  height: 34px;
  text-align: center;
  background: rgba(0, 131, 227);
  border-radius: 6px;
  line-height: 34px;
  color: #ffffff;
}
.cancel {
  width: 115px;
  height: 34px;
  background: rgba(232, 232, 232);
  border-radius: 6px;
  line-height: 34px;
  text-align: center;

  color: #999999;
}
</style>
